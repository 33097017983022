import UI from './core.js';
import $ from 'jquery';

/*global flowplayer */
/*global google */

UI.ComponentManager.register('top', function(context) {

	var searchClicked = function(e) {
			if (context.hasClass('transition')) {
				return;
			}
			var searchActive = $(document.body).hasClass('search-active'),
				input = context.find('input[type=text]'),
				inputval = $.trim(input.val());
			if (!searchActive) {
				if ($('#preview').hasClass('active')) {
					$('.ui-component-file').getComponent().close();
				}
				$(document.body).addClass('search-active');
				input.select();
				input.focus();
			} else {
				$(document.body).removeClass('search-active');
			}
		},

		homeClicked = function(e) {
			window.location = $(document.body).data('rootSrc');
		},

		logout = function() {
			window.location = '/banklogout';
		},

		toggleCart = function(e) {
			var cart = $('#cart');
			cart.toggleClass('active');
			if (!cart.hasClass('active')) {
				cart.getComponent().hideConversions();
			}
			$(document.body).toggleClass('cart-active', cart.hasClass('active'));
		},

		searchUnfocused = function(e) {
			$(document.body).removeClass('search-active');
			context.addClass('transition');
			setTimeout(function() {
				context.removeClass('transition');
			}, UI.SHOW_DURATION);
		},

		toggleLanguageSelector = function() {
			var options = $(this).offset();
			options.minWidth = $(this).width();
			$(this).find('.ui-component-dropdownlist').getComponent().open(options);
		},

		languageSelected = function() {
			window.location = $(this).data('languageUrl');
		},

		toggleAnalytics = function() {
			if ($('#preview').is('.active')) {
				$('.ui-component-file').getComponent().close();
				$(document.body).addClass('analytics-active');
			} else {
				$(document.body).toggleClass('analytics-active');
			}
			$('.ui-component-analyticspanel').getComponent().refresh();
		};

	this.toggleCart = function() {
		toggleCart();
	};

	this.onActivate = function(e) {
		context.on('click', '.home', homeClicked);
		context.on('click', '.searchbutton', searchClicked);
		context.on('click', '.logout', logout);
		context.on('click', '.cart', toggleCart);
		context.on('click', '.language-selector', toggleLanguageSelector);
		context.on('click', '.analyticsbutton', toggleAnalytics);
		context.on('focusout', 'input[type=text]', searchUnfocused);
	};

	this.onDeactivate = function(e) {
		context.off('click', '.home', homeClicked);
		context.off('click', '.searchbutton', searchClicked);
		context.off('click', '.logout', logout);
		context.off('click', '.cart', toggleCart);
		context.off('click', '.language-selector', toggleLanguageSelector);
		context.off('click', '.analyticsbutton', toggleAnalytics);
		context.off('focusout', 'input[type=text]', searchUnfocused);
	};

	this.initialize = function(context) {
		UI.ActionManager.register('selectLanguage', languageSelected, context);
	};
});

UI.ComponentManager.register('searchutils', function(context) {
	var validModes = ['all', 'file', 'image', 'video', 'audio', 'folder'],

		// TODO large mode with no dropdown
		typeToggle = function(e) {
			if ($(this).hasClass('select-mode')) {
				if (validModes.indexOf(e.target.className) != -1) {
					$(document.body).children('.container')
						.removeClass('mode-all')
						.removeClass('mode-video')
						.removeClass('mode-audio')
						.removeClass('mode-file')
						.removeClass('mode-image')
						.removeClass('mode-folder')
						.addClass('mode-' + e.target.className);
					$('.ui-component-filelisting').getComponent().checkEmpty();
				}
			}
			$(this).toggleClass('select-mode');
		};

	this.onActivate = function(e) {
		context.on('click', '.type', typeToggle);
	};

	this.onDeactivate = function(e) {
		context.off('click', '.type', typeToggle);
	};

});

UI.ComponentManager.register('filelisting', function(context) {
	var scrollTop = 0,

		openPreview = function(e) {
			var file = $(this).closest('.file'),
				fileObj = {
					src: file.data('src'),
					historySrc: file.data('historySrc'),
					fromListing: true
				};
			file.addClass('loading');
			context.getComponent().openPreview(fileObj);
		},

		openFolder = function(e) {
			window.location = $(this).data('src');
		},

		toggleCart = function(e) {
			var file = $(this).closest('.file');
			context.getComponent().toggleCart(file.data('fileid'), file.find('.addtocart').children().hasClass('icon-basket-added'), $.trim(file.children('.name').html()));
		},

		download = function(e) {
			var file = $(this).closest('.file');
			window.location = context.data('downloadOriginalUrl') + file.data('fileid');
		},

		onScroll = function(e) {
			var newScrollTop = context.scrollTop();
			if ($('#maincontainer').hasClass('hidenav')) {
				if (newScrollTop < scrollTop - 60) {
					$('#maincontainer').removeClass('hidenav');
					scrollTop = newScrollTop;
				} else if (newScrollTop > scrollTop) {
					scrollTop = newScrollTop;
				}
			} else {
				if (newScrollTop > scrollTop + 60) {
					$('#maincontainer').addClass('hidenav');
					scrollTop = newScrollTop;
				} else if (newScrollTop < scrollTop) {
					scrollTop = newScrollTop;
				}
			}
		},

		previewLoaded = function() {
			context.find('.file.loading').removeClass('loading');
		};

	this.download = function(fileid, conversion) {
		if (conversion) {
			window.location = context.data('downloadConversionUrl').replace('%conversion', conversion) + fileid;
		} else {
			window.location = context.data('downloadOriginalUrl') + fileid;
		}
	};

	this.checkEmpty = function() {
		context.toggleClass('no-results', !context.children('ul').children(':visible').length);
		// The following is needed because Foundation block grid doesn't support invisible elements among elements of the grid
		if (!context.hasClass('no-results')) {
			context.children('ul').children().not(':visible').each(function() {
				if (!$(this).data('originalSibling')) {
					// Set siblings for all in the listing
					context.children('ul').children().each(function() {
						$(this).data('originalSibling', $(this).prev());
					});
				}
				$(this).addClass('hidden').parent().append(this); // move to the end of the list and mark as being hidden
			});
			context.children('ul').children('.hidden:visible').each(function() {
				var targetSibling = $(this).data('originalSibling');
				while (targetSibling.length && targetSibling.is('.hidden')) {
					targetSibling = targetSibling.data('originalSibling');
				}
				if (targetSibling.length) {
					targetSibling.after(this);
				} else {
					$(this).parent().prepend(this);
				}
				$(this).removeClass('hidden');
			});
		}
	};

	this.toggleCart = function(fileId, remove, name) {
		if (remove) {
			UI.FlareManager.create(UI.translate('cart', 'removedFromCart'), true);
			context.find('.file[data-fileid="' + fileId + '"]').find('.addtocart').children().removeClass('icon-basket-added').addClass('icon-basket-add');
			UI.ajax({
				type: 'POST',
				url: '/selection/cartRemove/' + fileId,
				success: function(resp) {
					UI.ActionManager.trigger('cartUpdated', resp);
				}
			});
		} else {
			UI.FlareManager.create(UI.translate('cart', 'addedToCart'), true);
			context.find('.file[data-fileid="' + fileId + '"]').find('.addtocart').children().removeClass('icon-basket-add').addClass('icon-basket-added');
			UI.ajax({
				type: 'POST',
				url: '/selection/cartAdd/' + fileId,
				success: function(resp) {
					UI.ActionManager.trigger('cartUpdated', resp);
				}
			});
		}
	};

	this.open = function() {
		// Close possibly open preview
		$('#preview').removeClass('active');
		UI.HistoryManager.pushState(context.data('src'), null);
	};

	this.openPreview = function(fileObj, fromHistory, onComplete) {
		var src = fileObj.src,
			historySrc = fileObj.historySrc;
		UI.loadInPlace(src, '#preview', {
			history: !fromHistory,
			historyUrl: historySrc,
			historyState: {type: 'fileopen', src: src, historySrc: historySrc, fromListing: fileObj.fromListing},
			success: function() {
				if (onComplete) {
					onComplete();
				}
				$(document).foundation();
				$(document).foundation('interchange', 'reflow');
				$('#preview').find('.image img').on('load', function() {
					$(document.body).removeClass('search-active');
					$('.ui-component-file').getComponent().initHeight();
					$('#preview').addClass('active');
					setTimeout($('.ui-component-file').getComponent().onScroll, 500);
					$('#preview').find('.image img').off('load');
					if (fileObj.scrollTo) {
						setTimeout(function() { $(fileObj.scrollTo).scrollTo(); }, 500);
					}
				});
			}
		});
	};

	this.initializeScroll = function() {
		context.on('scroll', onScroll);
	};

	this.onActivate = function(e) {
		UI.ActionManager.register('preview.loaded', previewLoaded, context);
		context.on('click', '.file .thumb, .file .actions .preview', openPreview);
		context.on('click', '.folder', openFolder);
		context.on('click', '.addtocart', toggleCart);
		context.on('click', '.download', download);
	};
	this.onDeactivate = function(e) {
		context.off('click', '.file .thumb, .file .actions .preview', openPreview);
		context.off('click', '.folder', openFolder);
		context.off('click', '.addtocart', toggleCart);
		context.off('click', '.download', download);
	};
});

UI.ComponentManager.register('file', function(context) {
	var imageWrapper = context.find('.image'),
		img = imageWrapper.children('img'),
		pdf = imageWrapper.children('object'),
		flowplayerElement = imageWrapper.children('.flowplayer'),
		pdfElement = imageWrapper.children('.pdf'),
		imgHeight,
		scrollTop = 0,
		close = function(e) {
			$('#cart').find('.file-active').removeClass('file-active');
			$('#previewactions-clone').parent().remove();
			$('#previewbreadcrumb-clone').parent().remove();
			$('.ui-component-filelisting').getComponent().open();
		},
		updateNextPrev = function() {
			var file = $('#cart').find('li.file-active[data-id="' + context.data('id') + '"]');
			if (!file.length) {
				file = $('.file[data-fileid="' + context.data('id') + '"]').parent();
			}
			context.find('.actions .next').toggleClass('disabled', !file.next().length);
			context.find('.actions .prev').toggleClass('disabled', !file.prev().not('.type-folder').length);
		},
		next = function(e) {
			var file = $('#cart').find('li.file-active[data-id="' + context.data('id') + '"]');
			if (file.length) {
				file = file.next();
				if (file.length) {
					file.prev().removeClass('file-active');
					file.addClass('file-active');
				}
			} else {
				file = $('.file[data-fileid="' + context.data('id') + '"]').parent().next().children('.file');
			}
			if (file.length) {
				$(this).addClass('loading');
				$('.ui-component-filelisting').getComponent().openPreview({src: file.data('src'), historySrc: file.data('historySrc')});
			}
		},
		prev = function(e) {
			var file = $('#cart').find('li.file-active[data-id="' + context.data('id') + '"]');
			if (file.length) {
				file = file.prev();
				if (file.length) {
					file.next().removeClass('file-active');
					file.addClass('file-active');
				}
			} else {
				file = $('.file[data-fileid="' + context.data('id') + '"]').parent().prev().children('.file');
			}
			if (file.length) {
				$(this).addClass('loading');
				$('.ui-component-filelisting').getComponent().openPreview({src: file.data('src'), historySrc: file.data('historySrc')});
			}
		},
		openConversions = function() {
			var conversions = context.find('.actions .conversions'),
				options = conversions.offset();
			options.minWidth = conversions.width();

			conversions.find('.ui-component-dropdownlist').getComponent().open(options);
		},
		toggleCart = function() {
			$('.ui-component-filelisting').getComponent().toggleCart(context.data('id'), context.hasClass('incart'));
			context.toggleClass('incart');
			$('#previewactions-clone').toggleClass('incart');
		},
		conversionSelected = function() {
			context.find('.actions .conversions .text').text($(this).text());
			context.data('activeConversion', $(this).data('quicktag'));
			$('#previewactions-clone').find('.conversions .text').text($(this).text());
		},
		download = function() {
			$('.ui-component-filelisting').getComponent().download(context.data('id'), context.data('activeConversion'));
		},
		initActions = function() {
			var clonedActions = $('#previewactions-clone'),
				clonedBreadcrumb = $('#previewbreadcrumb-clone');
			if (!clonedBreadcrumb.length || clonedBreadcrumb.data('id') != context.data('id')) {
				if (clonedBreadcrumb.length) {
					clonedBreadcrumb.parent().remove();
				}
				clonedBreadcrumb = $('#previewbreadcrumb').clone();
				clonedBreadcrumb.attr('id', 'previewbreadcrumb-clone');

				setTimeout(function() {
					$('#maincontainer').append($('<div class="previewbreadcrumb-container">').append(clonedBreadcrumb));
					clonedBreadcrumb.parent().css('right', $.scrollbarWidth());
				}, 500);
			}
			if (!clonedActions.length || clonedActions.data('id') != context.data('id')) {
				if (clonedActions.length) {
					clonedActions.parent().remove();
				}
				clonedActions = $('#previewactions').clone();
				clonedActions.attr('id', 'previewactions-clone');
				clonedActions.on('click', '.conversions .toggler', openConversions);
				clonedActions.on('click', '.close', close);
				clonedActions.on('click', '.next', next);
				clonedActions.on('click', '.prev', prev);
				clonedActions.on('click', '.add-to-cart', toggleCart);
				clonedActions.on('click', '.download', download);

				$('#maincontainer').append($('<div class="previewactions-container">').append(clonedActions));
				clonedActions.parent().css('right', $.scrollbarWidth());
			}
		};
	this.next = function() {
		next();
	};
	this.prev = function() {
		prev();
	};
	this.close = function() {
		close();
	};
	this.onScroll = function() {
		var cloneOffset = $('#previewactions-clone').offset(), newScrollTop;
		if (cloneOffset) {
			$('#maincontainer').toggleClass('clonedactions', cloneOffset.top < $('#previewactions').offset().top);
			newScrollTop = $('#preview').scrollTop();
			if ($('#maincontainer').hasClass('hidenav')) {
				if (newScrollTop < scrollTop - 60) {
					$('#maincontainer').removeClass('hidenav');
					scrollTop = newScrollTop;
				} else if (newScrollTop > scrollTop) {
					scrollTop = newScrollTop;
				}
			} else {
				if (newScrollTop > scrollTop + 60) {
					$('#maincontainer').addClass('hidenav');
					scrollTop = newScrollTop;
				} else if (newScrollTop < scrollTop) {
					scrollTop = newScrollTop;
				}
			}
		}
		$('#maincontainer').toggleClass('clonedbreadcrumb', context.find('.details').offset().top > 90);
	};
	this.initHeight = function() {
		var flplayerapi;
		context.removeClass('small');
		imageWrapper.removeClass('inited');
		imageWrapper.css({
			height: ''
		});
		if (pdfElement.length) {
			imageWrapper.css({
				height: 'auto',
				left: 0
			});
			return;
		}
		if (flowplayerElement.length) {
			imageWrapper.css({
				height: 'auto',
				left: 0
			});
			imageWrapper.addClass('inited');
			context.addClass('small');
			flplayerapi = flowplayer(flowplayerElement.flowplayer());
			if (flplayerapi) {
				flplayerapi.bind('error', function(e, api) {
					flowplayerElement.remove();
					flowplayerElement = [];
					imageWrapper.css('left', '');
					img.css('display','');
					context.getComponent().initHeight();
				});
			}
			context.getComponent().onScroll();
			return;
		}
		imgHeight = img.height();
		if (imgHeight == 0) {
			return;
		}
		if (imgHeight <= 150) {
			imageWrapper.css({
				height: imgHeight + context.find('.breadcrumb').outerHeight()
			});
		}
		imageWrapper.addClass('inited');
		if (imgHeight <= 150) {
			context.addClass('small');
			imageWrapper.css({
				height: imgHeight + context.find('.breadcrumb').outerHeight()
			});
		} else {
			context.removeClass('small');
			imageWrapper.css({
				height: ''
			});
		}
		context.getComponent().onScroll();
	},
	this.close = function() {
		close();
	};
	this.onActivate = function(e) {
		context.on('click', '.conversions .toggler', openConversions);
		context.on('click', '.close', close);
		context.on('click', '.next', next);
		context.on('click', '.prev', prev);
		context.on('click', '.add-to-cart', toggleCart);
		context.on('click', '.download', download);
	};
	this.onDeactivate = function(e) {
		context.off('click', '.conversions .toggler', openConversions);
		context.off('click', '.close', close);
		context.off('click', '.next', next);
		context.off('click', '.prev', prev);
		context.off('click', '.add-to-cart', toggleCart);
		context.off('click', '.download', download);
	};
	this.initialize = function(context) {
		UI.ActionManager.register('file.selectConversion', conversionSelected, context);
		UI.ActionManager.register('windowResizeComplete', context.getComponent().onScroll, context);
		updateNextPrev();
		context.getComponent().initHeight();
		initActions();
		context.find('.image img').load(function() {
			$(this).css({
				height: '',
				width: ''
			});
		});
		context.uiAction('preview.loaded');
	};
});

UI.ComponentManager.register('cart', function(context) {
	var missingFiles = [],
		clearCart = function() {
			UI.ajax({
				url: '/selection/purge',
				success: function() {
					$('.ui-component-filelisting').find('.file').each(function() {
						var carticon = $(this).find('.addtocart').children();
						if (carticon.hasClass('icon-basket-added')) {
							carticon.removeClass('icon-basket-added').addClass('icon-basket-add');
						}
					});
					context.getComponent().cartUpdated([]);
				}
			}, null, true);
		},
		close = function(e) {
			$('.ui-component-top').getComponent().toggleCart();
		},
		removeItem = function(e) {
			$('.ui-component-filelisting').getComponent().toggleCart($(this).closest('li').data('id'), true);
			context.getComponent().hideConversions();
		},
		refreshCartCount = function(count) {
			$('#cart-count').text(context.find('.items ul li').length);
		},
		conversionSelected = function() {
			context.children('footer').find('.select .text').text($(this).text());
			context.data('activeConversion', $(this).data('quicktag'));
		},
		openConversions = function() {
			var conversions = context.children('footer').find('.conversions'),
				options = conversions.offset(),
				dropdown = conversions.find('.ui-component-dropdownlist');
			options.minWidth = conversions.width();

			if (dropdown.length) {
				dropdown.getComponent().open(options);
			}
		},
		showConversionSelect = function() {
			var showfunc = function() {
				context.children('footer').children('.select').addClass('shown');
				context.addClass('select-shown');
			};
			context.children('footer').children('.select').addClass('visible');
			if (window.requestAnimationFrame) {
				requestAnimationFrame(showfunc);
			} else {
				setTimeout(showfunc, 0);
			}
		},
		getConversions = function() {
			showConversionSelect();
			UI.ajax({
				url: context.data('conversionsSrc'),
				success: function(resp) {
					context.children('footer').find('.conversions').html(resp);
					context.data('activeConversion', '');
				}
			});
		},
		downloadClicked = function(e) {
			if (context.children('footer').children('.select').is(':visible'))
			{
			// do download
				window.location = '/selection/download' + (context.data('activeConversion') ? '/' + context.data('activeConversion') : '');
			} else {
			// fetch available conversions
				getConversions();
				return;
			}
		},
		itemClicked = function(e) {
			if ($(e.target).closest('.remove').length) {
				return;
			}
			var fileObj = {
				src: $(this).data('src'),
				historySrc: $(this).data('historySrc'),
				fromListing: false
			};
			$(this).addClass('file-active').siblings().removeClass('file-active');
			$('.ui-component-filelisting').getComponent().openPreview(fileObj);
			if ($(document.body).width() < 1400) {
				close();
			}
		};
	this.hideConversions = function() {
		context.children('footer').children('.select').removeClass('shown').removeClass('visible');
		context.removeClass('select-shown');
	};
	this.cartUpdated = function(cartData) {
		var newMissingFiles = [];
		// Check which files are not rendered yet
		$.each(cartData, function(i, fileId) {
			var file = context.find('li[data-id="' + fileId + '"]');
			if (!file.length) {
				if (missingFiles.indexOf(fileId) == -1) {
					newMissingFiles.push(fileId);
				}
			} else {
				// It might be hidden if it was attempted to be removed but it wasn't successful
				context.removeClass('hidden');
			}
		});
		missingFiles = newMissingFiles;
		// Check which files are rendered but not in cart anymore
		context.find('.items li').each(function() {
			if (cartData.indexOf($(this).data('id')) == -1) {
				$(this).remove();
			}
		});
		// Get files to render
		if (missingFiles.length) {
			UI.ajax({
				url: '/publicfolder/cartFiles/' + $(document.body).data('rootId'),
				data: {'fileIds[]': missingFiles},
				success: function(resp) {
					context.find('.items ul').append(resp);
					$(document).foundation();
					$(document).foundation('interchange', 'reflow');
					refreshCartCount();
				}
			});
		}
		context.getComponent().hideConversions();
		refreshCartCount();
	};
	this.onActivate = function(e) {
		context.on('click', '.items li', itemClicked);
		context.on('click', '.select .toggler', openConversions);
		context.on('click', '.download', downloadClicked);
		context.on('click', '.close', close);
		context.on('click', '.remove', removeItem);
		context.on('click', '.clear', clearCart);
	};
	this.onDeactivate = function(e) {
		context.off('click', '.items li', itemClicked);
		context.off('click', '.select .toggler', openConversions);
		context.off('click', '.download', downloadClicked);
		context.off('click', '.close', close);
		context.off('click', '.remove', removeItem);
		context.off('click', '.clear', clearCart);
	};
	this.initialize = function(context) {
		UI.ActionManager.register('cart.selectConversion', conversionSelected, context);
	};
});

UI.ComponentManager.register('dropdownlist', function(context) {
	var origin = {},

		bodyClick = function(e) {
			if ($(e.target).closest('.ui-component-dropdownlist').length) {
				return;
			}
			context.getComponent().close();
		},
		resolveOrigin = function() {
			origin.element = context.prev();
			if (!origin.length) {
				origin.element = context.parent();
				origin.type = 'child';
			} else {
				origin.type = 'sibling';
			}
		},
		handleClick = function(e) {
			var target = $(e.target).closest('.dropdown-target');
			if (target.length) {
				target.uiAction(target.data('action'));
			}
			context.getComponent().close();
		};
	this.open = function(opts) {
		var winHeight = $(window).height(),
			top = opts.top || 0;
		if (!origin.type) {
			resolveOrigin();
		}
		$(opts.target || document.body).append(context);
		context.css({
			top: opts.top || 0,
			left: opts.left || 0,
			minWidth: opts.minWidth || '',
			maxHeight: winHeight,
			visibility: 'hidden'
		});
		context.addClass('active');
		if (top + context.height() > winHeight) {
			context.css('top', winHeight - context.height());
		}
		context.css('visibility', '');
		setTimeout(function() {
			$(document.body).on('click mousewheel', bodyClick);
		}, 0);
	};
	this.close = function() {
		context.css({
			top: '',
			left: ''
		});
		context.removeClass('active');
		if (origin.type == 'child') {
			origin.element.prepend(context);
		} else {
			origin.element.after(context);
		}
		$(document.body).off('click mousewheel', bodyClick);
	};

	this.onActivate = function(e) {
		context.on('click', handleClick);
	};
	this.onDeactivate = function(e) {
		context.off('click', handleClick);
	};
});

UI.ComponentManager.register('folderutils', function(context) {
	var selectAll = function(e) {
			var items = [];
			UI.FlareManager.create(UI.translate('cart', 'allAddedToCart'), true);
			$('.ui-component-filelisting').find('.file').each(function() {
				var carticon = $(this).find('.addtocart').children();
				if (carticon.hasClass('icon-basket-add')) {
					carticon.removeClass('icon-basket-add').addClass('icon-basket-added');
					items.push($(this).data('fileid'));
				}
			});
			UI.ajax({
				url: '/selection/cartAdd',
				data: {fileId: items},
				success: function(resp) {
					UI.ActionManager.trigger('cartUpdated', resp);
				}
			});
		},
		download = function(e) {
			window.location = '/publicfolder/download/' + $('.ui-component-filelisting').data('folderid');
		};
	this.onActivate = function(e) {
		context.on('click', '.select-all', selectAll);
		context.on('click', '.download', download);
	};
	this.onDeactivate = function(e) {
		context.off('click', '.select-all', selectAll);
		context.off('click', '.download', download);
	};
});

UI.ComponentManager.register('childstrip', function(context) {
	var loadStrip = function() {
		UI.ajax({
			url: context.data('src'),
			type: 'get',
			success: function(resp) {
				if (resp.length) {
					context.addClass('with-items');
					context.prepend(resp);
				}
			},
			error: function() {
				context.remove();
			}
		});
	};
	this.onActivate = function(e) {
	};
	this.onDeactivate = function(e) {
	};
	this.initialize = function(context) {
		loadStrip();
	};
});

UI.ComponentManager.register('resultloader', function(context) {
	var parent, loading = false,
		checkLoad = function() {
			if (loading) {
				return;
			}
			var parentHeight = parent.height(),
				pos = context.position().top;
			if (pos < parentHeight + 150) {
			// start indicating load
				loading = true;
				context.addClass('loading');
				UI.ajax({
					url: context.data('url'),
					data: context.data('params'),
					type: context.data('params') ? 'post' : 'get',
					success: function(resp, status, xhr) {
						if (!$.trim(resp).length) {
							parent.off('scroll', checkLoad);
							context.addClass('nomore');
							setTimeout(function() {
								context.remove();
							}, 1000);
							return;
						}
						context.data('url', xhr.getResponseHeader('X-CM-Resultloader-URL'));
						context.prev().append(resp);
						$(document).foundation();
						$(document).foundation('interchange', 'reflow');
					},
					complete: function() {
					// stop indicating load
						loading = false;
						context.removeClass('loading');
					},
					error: function() {
						parent.off('scroll', checkLoad);
						context.remove();
					}
				}, null, !context.data('params'));
			}
		}
	this.onActivate = function(e) {
	};
	this.onDeactivate = function(e) {
	};
	this.initialize = function(context) {
		parent = context.getScrollParent();
		parent.on('scroll', checkLoad);
	};
});

UI.ComponentManager.register('analyticspanel', function(context) {
	var initChart = function() {
			var topfiles = $('.topfiles'),
				data = [
					[UI.translate('analytics', 'file'), UI.translate('analytics', 'downloads')]
				],
				dataTable, options, chart;
			topfiles.children('.file').each(function() {
				data.push([$(this).data('data').name, parseInt($(this).data('data').downloads, 10)]);
			});
			dataTable = google.visualization.arrayToDataTable(data);

			options = {
				legend: 'none',
				chartArea: {
					top: '10%',
					height: '80%'
				},
				pieSliceText: 'percentage',
				colors: topfiles.data('colors'),
				title: UI.translate('analytics', 'top10share')
			};

			chart = new google.visualization.PieChart(context.find('.piecontainer')[0]);

			chart.draw(dataTable, options);
		},
		openFile = function(e) {
			var file = $(this),
				fileObj = {
					src: file.data('src'),
					historySrc: file.data('historySrc'),
					fromListing: true,
					scrollTo: '#fileanalytics'
				};
			file.addClass('loading');
			$('.ui-component-filelisting').getComponent().openPreview(fileObj, false, function() { file.removeClass('loading'); });
		},
		reloadAnalytics = function(e) {
			UI.loadInPlace(context.data('reloadSrc'), '#analyticspanel', {
				data: {datestart: $('#datestart').val(), dateend: $('#dateend').val()},
				success: function() {
					$(document).foundation();
					$(document).foundation('interchange', 'reflow');
				}
			});
		},
		scopeChanged = function(e) {
			$(this).addClass('loading');
			UI.loadInPlace($(this).data('src'), '#analyticspanel', {
				success: function() {
					$(document).foundation();
					$(document).foundation('interchange', 'reflow');
				}
			});
		};
	this.refresh = function() {
		initChart();
	};
	this.onActivate = function(e) {
		context.on('click', '.file', openFile);
		context.on('click', '.changedate', reloadAnalytics);
		context.on('click', '.scope div', scopeChanged);
	};
	this.onDeactivate = function(e) {
		context.off('click', '.file', openFile);
		context.off('click', '.changedate', reloadAnalytics);
		context.off('click', '.scope div', scopeChanged);
	};
	this.initialize = function(context) {
		google.load('visualization', '1.1', {packages: ['corechart'], callback: initChart});
	};
});

UI.ComponentManager.register('fileanalytics', function(context) {
	var initChart = function() {
			var data = $('.graph').data('data'),
				dataTable,
				options,
				chart,
				dateFormatter, numberFormatter;
			data = data.map(function(row) {
				row[0] = new Date(row[0].substr(0, 4), parseInt(row[0].substr(4, 2), 10) - 1, row[0].substr(6,2));
				row[1] = parseInt(row[1], 10);
				return row;
			});
			dataTable = new google.visualization.DataTable();
			dataTable.addColumn('date', UI.translate('analytics', 'date'));
			dataTable.addColumn('number', UI.translate('analytics', 'downloads'));

			dataTable.addRows(data);

			dateFormatter = new google.visualization.DateFormat({
				formatType: 'medium'
			});
			dateFormatter.format(dataTable, 0);

			numberFormatter = new google.visualization.NumberFormat({
				pattern: '#'
			});
			numberFormatter.format(dataTable, 1);

			options = {
				vAxis: {
					minValue: 0,
					maxValue: 5,
					format: '#'
				},
				legend: {
					position: 'in'
				},
				chartArea: {
					top: 10,
					height: '78%'
				}
			};
			chart = new google.visualization.LineChart($('.graphcontainer')[0]);
			chart.draw(dataTable, options);
		},
		reloadAnalytics = function(e) {
			UI.loadInPlace(context.data('reloadSrc'), '#fileanalytics', {
				data: {datestart: $('#datestartfile').val(), dateend: $('#dateendfile').val()},
				success: function() {
				}
			});
		};
	this.onActivate = function(e) {
		context.on('click', '.changedate', reloadAnalytics);
	};
	this.onDeactivate = function(e) {
		context.off('click', '.changedate', reloadAnalytics);
	};
	this.initialize = function(context) {
		google.load('visualization', '1.1', {packages: ['corechart'], callback: initChart});
	};
});

$.fn.getScrollParent = function() {
	var container;
	this.parents().each(function() {
		var cont = $(this);
		if (cont.css('overflow-y') == 'auto' || cont.css('overflow-y') == 'scroll') {
			container = cont;
			return false;
		}
	});
	return container;
};

$(function() {
	window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
		window.webkitRequestAnimationFrame || window.msRequestAnimationFrame || function(func) { setTimeout(func, 16); };
	$(document).foundation();
	var initer = function() {
		var img = $(this);
		requestAnimationFrame(function() {
			img.closest('.ui-component-file').getComponent().initHeight();
			img.off('load', initer);
		});
	};
	$(document).on('replace', '.ui-component-file img', function (e, new_path, original_path) {
		$(e.target).on('load', initer);
	});

	$('#preview').touchwipe({
		wipeLeft: function() {
			$('.ui-component-file').getComponent().next();
		},
		wipeRight: function() {
			$('.ui-component-file').getComponent().prev();
		},
		min_move_x: 50
	});
	$('#cart').touchwipe({
		wipeRight: $('.ui-component-top').getComponent().toggleCart,
		min_move_x: 50
	});

	$('#preview').on('scroll', function() {
		$(this).children('.ui-component-file').getComponent().onScroll();
	});
	var fileListing = $('.ui-component-filelisting').getComponent();
	if (fileListing) {
		fileListing.checkEmpty();
		fileListing.initializeScroll();
	}
	UI.ActionManager.register('fileopen', function() {
		$('.ui-component-filelisting').getComponent().openPreview(this, true);
	});
	UI.ActionManager.register('cartUpdated', function() {
		$('.ui-component-cart').getComponent().cartUpdated(this);
	});
	UI.KeyManager.bind(UI.KeyManager.KEYLEFT, function() {
		var file = $('.ui-component-file');
		if (file.length) {
			file.getComponent().prev();
		}
	}, null, true);
	UI.KeyManager.bind(UI.KeyManager.KEYRIGHT, function() {
		var file = $('.ui-component-file');
		if (file.length) {
			file.getComponent().next();
		}
	}, null, true);
	UI.KeyManager.bind(UI.KeyManager.ESC, function() {
		var file = $('.ui-component-file');
		if (file.length) {
			file.getComponent().close();
		}
	}, null, true);
});
