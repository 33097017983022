import $ from 'jquery';


/**
 * Base UI class. Does things that affect either the whole UI or some
 * parts of it, without going too deep (into component level).
 */
var UI = window.UI = (function() {

	var api = {},
		clickDisabled = false;

	api.debuggingEnabled = location.search === '?debug';

	/**
	 * Duration of hide effects.
	 */
	api.HIDE_DURATION = 150;

	/**
	 * Duration of show effects.
	 */
	api.SHOW_DURATION = 250;

	/**
	 * Duration to delay showing something
	 */
	api.SHOWDELAY_DURATION = 600;

	/**
	 * Duration (timeout) of autohide effects.
	 */
	api.AUTOHIDE_DURATION = 1500;

	api.disableClick = function() {
		clickDisabled = true;
	};

	api.enableClick = function() {
		clickDisabled = false;
	};

	api.isClickDisabled = function(ele) {
		if (ele && $(ele).closest('.disabled').length > 0) {
			return true;
		}
		return clickDisabled;
	};

	api.clickEnabled = function(ele, func, context) {
		if (!api.isClickDisabled(ele)) {
			var argus = Array.prototype.slice.call(arguments, 3);
			func.apply(context ? context : ele, argus);
		}
	};

	/**
	 * @param {string|jQuery|Element|null} contextArea The area.
	 * Defaults to 'body'.
	 * @return {boolean} Whether the area is loading.
	 */
	api.isLoading = function(contextArea) {
		return $(contextArea || 'body').hasClass('ui-area-loading');
	};

	/**
	 * Marks a certain area (an element) as no longer being under heavy
	 * Ajax activity. Makes the element clickable again and removes the
	 * loading spinner.
	 *
	 * @see UI.markAsLoading
	 * @param {string|jQuery|Element|null} contextArea The area.
	 * Defaults to 'body'.
	 * @return {UI} The UI API.
	 */
	api.markAsLoaded = function(contextArea) {
		var ele = $(contextArea || 'body');
		if (ele.data('loadingtimer')) {
			clearTimeout(ele.data('loadingtimer'));
			ele.removeData('loadingtimer');
		}
		ele
			.filter('.ui-area-loading')
			.removeClass('ui-area-loading')
			.uiAction('loaded')
			.children('span.ui-area-loading-loader')
			.remove();
		UI.ComponentManager.triggerInitializers(contextArea);
		UI.ComponentManager.triggerSingleActions();
		return api;
	};

	function markAsLoading(ele, longAnimation, msg) {
		ele
			.not('.ui-area-loading')
			.addClass('ui-area-loading')
			.append($('<span class="ui-area-loading-loader" />'));
		if (longAnimation) {
			ele.children('.ui-area-loading-loader').css({
				opacity: 0.01
			}).animate({
				opacity: 1
			}, 1000);
		}
		if (msg) {
			ele.find('.ui-area-loading-spinner').addClass('with-msg').append(msg);
		}
	}

	/**
	 * Marks a certain area (an element) as an area that is undergoing
	 * heavy Ajax activity. Basically this makes the element unclickable
	 * and a loading spinner is shown.
	 *
	 * @see UI.markAsLoaded
	 * @param {string|jQuery|Element|null} contextArea The area.
	 * Defaults to 'body'.
	 * @return {UI} The UI API.
	 */
	api.markAsLoading = function(contextArea, lng, msg, modal) {
		var ele = $(contextArea || document.body);
		if (ele.data('loadingtimer')) {
			clearTimeout(ele.data('loadingtimer'));
			ele.removeData('loadingtimer');
		}
		ele.data('loadingtimer', setTimeout(function() {
			markAsLoading(ele, lng, msg);
		}, modal ? 0 : (lng ? 3000 : 600)));
		return api;
	};

	/**
	 * @param {object} data Data like for $.ajax call
	 */
	api.ajax = function(data, attempt) {
		var addtoken = (data.type == 'POST') ? true : false;
		var token = addtoken ? this.popToken() : null;

		if (addtoken) {

			if (!attempt) 
				attempt = 1;

			if (attempt > 50) {
				return;
			}

			if (token === undefined) {
				setTimeout(() => {
					this.ajax(data, ++attempt);
				}, 200);
				return;
			}

			data.data = this.addToken(data.data, token);
		}


		data.success = api.success(data.success);
		data.error = api.error(data.error);
		if (data.history) {
			UI.HistoryManager.pushState(data.historyUrl || data.url, null, data.historyState);
		}
		return $.ajax(data);
	};

	api.loadInPlace = function(src, target, opts, skipcheck) {
		var options = {
				context: $(document.body),
				append: false,
				data: '',
				tracking: false,
				history: false
			},
			scroller = $(target),
			inplaceTimer,
			scrollPosition = 0,
			protectedElements = $(target).find('.ui-component-load-protected'),
			cleanupElements = $(target).find('.ui-component-on-remove');
		if (!skipcheck) {
			protectedElements.each(function() {
				if (!$(this).getComponent().protectedAction(function() { api.loadInPlace(src, target, opts, true); })) {
					return true;
				}
				skipcheck = true;
				return false;
			});
			if (skipcheck) {
				return false;
			}
		}
		if (opts) {
			options = $.extend(options, opts);
		}
		$(target).data('inplaceTimer', $(target).data('inplaceTimer') ? $(target).data('inplaceTimer') + 1 : 1);
		inplaceTimer = $(target).data('inplaceTimer');
		options.context = $(options.context);
		if (!options.noLoadingIndicator) {
			UI.markAsLoading(target);
		}
		if (scroller.data('scroller')) {
			scroller.addClass('not-scrolling');
			scroller = scroller.find(scroller.data('scroller')).first();
		}
		if (options.preserveScroll) {
			scrollPosition = scroller.scrollTop();
		}
		UI.ajax({
			type: options.post ? 'POST' : 'GET',
			url: src,
			dataType: 'html',
			cache: false,
			history: options.history,
			historyUrl: options.historyUrl,
			historyState: options.historyState,
			data: options.data,
			context: this,
			beforeSend: function(xhr) {
				var action = options.context.data('loadpreaction');
				if(action) {
					options.context.uiAction(action);
				}
			},
			error: function (xhr) {
				if ($(target).data('inplaceTimer') == inplaceTimer) {
					UI.markAsLoaded(target);
					switch (xhr.status) {
						case 404:
							UI.MessageManager.addMessage('console', UI.translate('requestErrors', 'notFound'));
							break;
						default:
							UI.MessageManager.addMessage('console', UI.translate('ui', 'blocked'));
					}
				}
			},
			complete: function(xhr, status) {
				if ($(target).data('inplaceTimer') == inplaceTimer) {
					if (UI.isLoading(target)) {
						UI.markAsLoaded(target);
					}
				}
			},
			success: function(data, status, xhr) {
				if (options.tracking) {
					UI.AnalyticsManager.pageview(options.trackingHref || src, options.trackingTitle);
				}
				if ($(target).data('inplaceTimer') == inplaceTimer) {
					cleanupElements.each(function() {
						var comp = $(this).getComponent();
						if (comp) {
							comp.onRemove();
						}
					});
					UI.markAsLoaded(target);
					if (options.append) {
						$(target).append(data);
					} else if (options.replace) {
						$(target).replaceWith(data);
					} else {
						$(target).empty().append(data);
					}
					var action = options.context.data('loadpostaction');
					if(action) {
						options.context.uiAction(action);
					}
					$(window).resize();
					$(target).uiAction('contentUpdated');
				}
				if (options.success) {
					options.success(data);
				}
				if ($(target).data('scroller')) {
					scroller = $(target).find($(target).data('scroller')).first();
					if (!scroller.length) {
						scroller = $(target);
						scroller.removeClass('not-scrolling');
					}
				}
				if (options.preserveScroll) {
					scroller.scrollTop(scrollPosition);
				} else {
					scroller.scrollTop(0);
				}
			}
		});
		return api;
	};

	api.loadRun = function(src, post, data, context, successaction, modal) {
		if (modal) {
			UI.markAsLoading(document.body, false, context ? $(context).data('modalMessage') : null, true);
		}
		UI.ajax({
			type: post ? 'POST' : 'GET',
			url: src,
			data: data,
			cache: false,
			complete: function() {
				if (modal) {
					UI.markAsLoaded(document.body);
				}
			},
			success: function() {
				if (context && successaction) {
					$(context).uiAction(successaction);
				}
			}
		});
		return api;
	};

	api.error = function(callback) {
		return function(xhr) {
			var contentTypeHeader = xhr.getResponseHeader('Content-Type'),
				type = contentTypeHeader ? contentTypeHeader.split(';')[0] : 'text/html',
				dialog = xhr.getResponseHeader('X-CM-Dialog'), origin, search_context = xhr.getResponseHeader('X-CM-Search-Context'),
				data = xhr.responseText,
				error;
			if (callback) {
				callback.apply(this, arguments);
			}
			if (xhr.getResponseHeader('X-CM-Load') && xhr.getResponseHeader('X-CM-Load-Target')) {
				UI.loadInPlace(xhr.getResponseHeader('X-CM-Load'), xhr.getResponseHeader('X-CM-Load-Target'), {ajaxindex: xhr.getResponseHeader('X-CM-Load-Ajax-Index')});
			}
			UI.ComponentManager.triggerInitializers();
			UI.ComponentManager.triggerSingleActions();
			switch (xhr.status) {
				case 500:
					error = xhr.getResponseHeader('X-CM-Error');
					UI.MessageManager.addMessage('console', error || data.error || UI.translate('requestErrors', 'internalError'));
					break;
			}
		};
	}

	api.success = function(callback) {
		return function(data, status, xhr) {
			if (callback) {
				callback.apply(this, arguments);
			}
			if (xhr.getResponseHeader('X-CM-Load') && xhr.getResponseHeader('X-CM-Load-Target')) {
				UI.loadInPlace(xhr.getResponseHeader('X-CM-Load'), xhr.getResponseHeader('X-CM-Load-Target'), {ajaxindex: xhr.getResponseHeader('X-CM-Load-Ajax-Index')});
			}
			if (xhr.getResponseHeader('X-CM-Message')) {
				UI.MessageManager.addMessage('about', $.parseJSON(xhr.getResponseHeader('X-CM-Message')));
			}
			UI.ComponentManager.triggerInitializers();
			UI.ComponentManager.triggerSingleActions();
		};
	};

	api.translate = function(set, value) {
		return UI.I18nManager.get(set, value);
	};

	api.getLanguage = function() {
		return UI.I18nManager.getLanguage();
	};

api.popToken = function() {

	if ($(document.body).data('token').length == 1) {
		// if running out of tokens, get more
		if ($(document.body).data('tokenUrl')) {
			$.ajax({
				url: $(document.body).data('tokenUrl'),
				dataType: 'json',
				type: 'post',
				data: api.addToken({}, $(document.body).data('token').pop()),
				success: api.success(function(data, status, xhr) {
					var tokens = data.tokens || data.data.tokens;
					$.each(tokens, function(i, token) {
						api.pushToken(token);
					});
				})
			});
		}
	}

	return $(document.body).data('token').pop();
};

api.pushToken = function(token) {
	
	if (typeof $(document.body).data('token') == 'undefined') {
		$(document.body).data('token', []);
	}

	$(document.body).data('token').push(token);
};

api.addToken = function(data, tok) {
	
	var token = tok ? tok : api.popToken();
	
	if ($.isPlainObject(data)) {
		data = $.param(data);
	}

	if (data && data.length > 0) {
		data += '&';
	} 
	else {
		data = '';
	}

	data += $(document.body).data('tokenName') + '=' + token;

	return data;
};

	function loadInPlaceHandler() {
		var ajaxindex, options,
			target = $(this).data('loadtarget'),
			src = $(this).data('loadhref') || $(this).attr('href');
		if (!src) {
			throw new Error('Missing required attribute data-loadhref or href');
		}
		if (src == '#') {
			var action = $(this).data('loadpreaction');
			if(action) {
				$(this).uiAction(action, null);
			}
			return;
		}
		if ($(this).hasClass('disabled')) {
			return;
		}
		if (!target) {
			if ($(this).hasClass('load-append')) {
				target = 'body';
			} else {
				throw new Error('Missing required attribute data-loadtarget');
			}
		}
		options = {
			context: this,
			post: $(this).hasClass('as-post'),
			append: $(this).hasClass('load-append'),
			replace: $(this).hasClass('load-replace')
		};
		if ($(this).hasClass('tracking')) {
			$.extend(options, {
				tracking: true,
				trackingHref: $(this).data('trackingHref') || $(this).data('historyHref'),
				trackingTitle: $(this).data('trackingTitle')
			});
		}
		api.loadInPlace(src, target, options);
	}
	$(document).on('click', '.load-inplace, .load-replace, .load-append', function(e) {
		if ($(e.target).closest('.prevent-load', this).length) {
			return;
		}
		if ($(this).hasClass('just-clicked')) {
			e.preventDefault();
			return;
		}
		$(this).addClass('just-clicked');
		var self = this;
		setTimeout(function() {
			$(self).removeClass('just-clicked');
		}, 600);
		e.preventDefault();
		if (e.shiftKey && $(this).data('shiftHref')) {
			api.loadInPlace($(this).data('shiftHref'), $(this).data('loadtarget'), {
				ajaxindex: $(this).data('ajaxindex'),
				context: this,
				post: $(this).hasClass('as-post'),
				append: $(this).hasClass('load-append'),
				replace: $(this).hasClass('load-replace')
			});
		} else if (e.shiftKey && $(this).data('shiftAction')) {
			api.loadRun($(this).data('shiftAction'));
		} else {
			UI.clickEnabled(e.target, loadInPlaceHandler, this);
		}
		return false;
	});

	$(document).on('submit', '.form-inplace', function(e) {
		var form = $(this),
			post = form.attr('method') == 'post',
			opts = {post: post},
			route, inputs;
		e.preventDefault();
		if (form.hasClass('route-mode')) {
			route = [];
			inputs = form.data('routeFields').split(',');
			if (post) {
				opts.data = form.serializeObject();
			}
			$.each(inputs, function(i, name) {
				route.push(form.find('[name="' + name + '"]').val());
			});
			route = route.join('/');
			api.loadInPlace(form.attr('action') + route, form.data('target'), opts);
		} else if (post) {
			opts.data = form.serializeObject();
			api.loadInPlace(form.attr('action'), form.data('target'), opts);
		} else {
			api.loadInPlace(form.attr('action') + '?' + form.serialize(), form.data('target'), opts);
		}
	});

	$.fn.loadInPlace = function(src, opts) {
		return this.each(function() {
			api.loadInPlace(src, this, opts);
		});
	};

	$(document).on('click', '.load-run', function(e) {
		e.preventDefault();
		if ($(this).hasClass('do-confirm')) {
			if (!confirm($(this).data('confirm'))) {
				return;
			}
		}
		UI.clickEnabled(e.target, function() {
			var src;
			src = $(this).data('loadhref') || $(this).attr('href');
			if (!src) {
				throw new Error('Missing required attribute data-loadhref or href');
			}
			api.loadRun(src, $(this).hasClass('as-post'), $(this).data('data'), this, $(this).data('success-action'), $(this).hasClass('modal'));
		}, this);
	});

	$(document).on('click', '.prevent-default', function(e) {
		e.preventDefault();
	});

	return api;

})();

/**
 * Manages UI components.
 */
UI.ComponentManager = (function() {

	var api = {}, components = [], activeComponents = [], nestedActivation = false;

	function getComponentClassFromElement(element) {
		if (element.length == 0 || !element.hasClass('ui-component')) {
			return null;
		}
		var className = element.attr('class'), matched, type;
		if (className) {
			matched = className.match(/(?:\s|^)ui-component-([a-z]+)/);
			type = matched ? matched[1] : null;
		}
		if (!type) {
			return null;
		}
		if (!components[type]) {
			throw new Error('Definition of UI component "' + type + '" is missing');
		}
		return components[type];
	}

	function getComponentClassFromType(type) {
		if (!type || !components[type]) {
			throw new Error('Definition of UI component "' + type + '" is missing');
		}
		return components[type];
	}

	function activateParents(context, e) {
		nestedActivation = true;
		$.each(context.parents('.ui-component').get().reverse(), function() {
			if (!$(this).hasClass('ui-component-direct-activation')) {
				api.activate($(this), e);
			}
		});
		nestedActivation = false;
	}

	function attach(context) {
		if (context.length > 1) {
			context = $(context[0]);
		}
		var component = context.data('uicomponent'), parentComponent;
		if (!component) {
			component = getComponentClassFromElement(context);
			if (component === null) {
				return null;
			}
			context.data('uicomponent', component = new component(context));
			if (component.extendComponent) {
				parentComponent = getComponentClassFromType(component.extendComponent);
				context.data('uicomponentparent', component.parent = new parentComponent(context));
				$.each(component.parent, function(prop, obj) {
					if (!component[prop]) {
						component[prop] = obj;
					}
				});
			}
		}
		return component;
	}

	/**
	 * Activates a component by jQuery context. Triggered when an element
	 * with the CSS class "ui-component-focus" is focused inside the
	 * component, or on mousedown.
	 *
	 * @param {jQuery} context The element to activate.
	 * @param {Event} e The event that activated the component.
	 * @return {UI.ComponentManager} The UI.ComponentManager API.
	 */
	api.activate = function(context, e) {
		var component = attach(context), i;
		if (!nestedActivation) {
			// in theory the component may have been moved around in
			// the dom without a deactivation.. therefore this should
			// always run even if the component is currently active
			activateParents(context, e);
			// deactivate unrelated components
			for (i = 0; i < activeComponents.length; i++) {
				if (activeComponents[i] !== context[0] && (!activeComponents[i] || !$.contains(activeComponents[i], context[0]))) {
					i -= api.deactivateByContext(activeComponents[i], e);
				}
			}
		}
		// activate context if necessary
		if ($.inArray(context[0], activeComponents) == -1) {
			activeComponents.push(context[0]);
			if (component.parent && component.parent.onActivate && (!component.onActivate || component.onActivate !== component.parent.onActivate)) {
				component.parent.onActivate(e);
			}
			if (component.onActivate) {
				component.onActivate(e);
			}
		}
		return api;
	};

	/**
	 * Deactivates a component (by jQuery context). May be called from
	 * within a component.
	 *
	 * @param {jQuery} context The element to deactivate.
	 * @param {Event} e The event that deactivated the component.
	 * @return {UI.ComponentManager} The UI.ComponentManager API.
	 */
	api.deactivate = function(context, e) {
		api.deactivateByContext(context[0], e);
		return api;
	};

	/**
	 * Deactivates a component.
	 *
	 * @param {element} component The component instance to deactivate.
	 * @param {Event} e The event that deactivated the component.
	 * @return {int} How many were deactivated (0 or 1).
	 */
	api.deactivateByContext = function(context, e) {
		var index = $.inArray(context, activeComponents),
			removed = 0;
		if (index != -1) {
			var component = $(context).getComponent();
			activeComponents.splice(index, 1);
			removed++;
			if (component.onDeactivate) {
				component.onDeactivate(e);
			}
			if (component.parent && component.parent.onDeactivate && (!component.onDeactivate || component.onDeactivate !== component.parent.onDeactivate)) {
				component.parent.onDeactivate(e);
			}
		}
		return removed;
	};

	/**
	 * Initializes a component. This should be used as little as possible.
	 * Allows the component's contents to be modified on page load or
	 * after Ajax requests.
	 *
	 * Components that depend on this functionality must have the
	 * CSS class "ui-component-require-init". They must also contain an
	 * initializer element (see UI.ComponentManager.triggerInitializers())
	 *
	 * @see UI.ComponentManager.triggerInitializers
	 * @param {jQuery} context The element to initialize.
	 * @return {UI.ComponentManager} The UI.ComponentManager API.
	 */
	api.initialize = function(context) {
		var component = attach(context);
		if (component === null) {
			return api;
		}
		if (component.parent && component.parent.initialize && (!component.initialize || component.initialize !== component.parent.initialize)) {
			component.parent.initialize(context);
			delete component.parent.initialize;
		}
		if (component.initialize) {
			component.initialize(context);
			delete component.initialize;
		}
		return api;
	};

	/**
	 * Returns true if the context contains an active element, false
	 * otherwise.
	 *
	 * @param {jQuery|Element}
	 * @return {bool} Whether any of the active elements is contained
	 * by the context.
	 */
	api.isActiveWithin = function(context) {
		var i;

		context = $(context);

		// the last item is more likely to be the deepest
		// in the hierarchy
		for (i = activeComponents.length - 1; i >= 0; --i) {
			if ($.contains(context[0], activeComponents[i])) {
				return true;
			}
		}
		return false;
	};

	/**
	 * Registers a component. All elements that have the "ui-component"
	 * CSS class must also have a "ui-component-NAME" class, where NAME
	 * is the name of the component (in lowercase).
	 *
	 * @param {string} name The name of the component.
	 * @param {function} component The component constructor function.
	 * @return {UI.ComponentManager} The UI.ComponentManager API.
	 */
	api.register = function(name, component) {
		components[name] = component;
		return api;
	};

	/**
	 * Triggers all component initializers within the context. The trigger
	 * element must match "DFN.ui-initializer". The closest containing
	 * component with the "ui-component-require-init" CSS class is then
	 * initialized with UI.ComponentManager.initialize(). The
	 * "ui-component-initialized" CSS class is also added to the component.
	 *
	 * This function is called when the DOM is ready and after non-JSON
	 * Ajax requests (in a global ajaxSuccess handler).
	 *
	 * Do not overuse. The less you rely on this the better.
	 *
	 * @see UI.ComponentManager.initialize
	 * @param {jQuery|string|Element} context The DOM node to go through.
	 * @return {UI.ComponentManager} The UI.ComponentManager API.
	 */
	api.triggerInitializers = function(context) {
		$('dfn.ui-initializer', context || document).each(function() {
			var initElement = $(this).closest('.ui-component-require-init');
			if (!initElement.hasClass('ui-component-initialized')) {
				$(this).remove();
				api.initialize(initElement.addClass('ui-component-initialized'));
			}
		});
		return api;
	};

	api.triggerSingleActions = function() {
		$('dfn.ui-singleaction').each(function() {
			$(this).uiAction($(this).data('action'), $(this).data('actionData'));
			$(this).remove();
		});
	};

	$(document).on('focusin', '.ui-component .ui-component-focus, .ui-component-on-focus', function(e) {
		api.activate($(this).closest('.ui-component'), e);
	});

	/**
	 * Receives the ui component object for given context (if it has one)
	 */
	api.getComponent = function(context) {
		return attach($(context));
	};

	$(document).on('mousedown touchstart', '.ui-component', function(e) {
		e.stopPropagation();
		api.activate($(this), e);
	});

	$(function() {
		api.triggerInitializers();
		api.triggerSingleActions();
		$('#logo a').bind('click', function(e) {
			e.preventDefault();
			$('#endpoint-selector').slideToggle('fast');
		});
	});

	$.fn.getComponent = function() {
		return api.getComponent(this);
	};

	return api;

})();

/**
 * Internal key manager to handle some keys globally
 */
UI.KeyManager = (function() {
	var api = {},
		actions = {},
		priorityActions = {},

		keyListener = function(e) {
			var actiondone = false, noprevent = false;
			if (priorityActions[e.which] && priorityActions[e.which].length) {
				$.each(priorityActions[e.which], function(i, func) {
					noprevent = func[2];
					if (!func[1]) {
						func[0]();
						actiondone = true;
					} else if (func[1] == api.SHIFT && e.shiftKey) {
						func[0]();
						actiondone = true;
					} else if (func[1] == api.CONTROL && e.ctrlKey) {
						func[0]();
						actiondone = true;
					} else if (func[1] == api.CMD && e.metaKey) {
						func[0]();
						actiondone = true;
					}
				});
			}
			if (!actiondone && actions[e.which]) {
				$.each(actions[e.which], function(i, func) {
					noprevent = func[2];
					if (!func[1]) {
						func[0]();
						actiondone = true;
					} else if (func[1] == api.SHIFT && e.shiftKey) {
						func[0]();
						actiondone = true;
					} else if (func[1] == api.CONTROL && e.ctrlKey) {
						func[0]();
						actiondone = true;
					} else if (func[1] == api.CMD && e.metaKey) {
						func[0]();
						actiondone = true;
					}
				});
			}
			if (actiondone && !noprevent) {
				e.preventDefault();
				return false;
			}
		};

	api.ESC = 27;
	api.KEYLEFT = 37;
	api.KEYRIGHT = 39;
	api.S = 83;
	api.SHIFT = 'shift';
	api.CONTROL = 'control';
	api.CMD = 'cmd';

	api.bind = function(action, func, modifier, noprevent) {
		if (typeof actions[action] == 'undefined') {
			actions[action] = [];
		}
		actions[action].push([func, modifier, noprevent]);
	};

	api.priorityBind = function(action, func, modifier, noprevent) {
		if (typeof priorityActions[action] == 'undefined') {
			priorityActions[action] = [];
		}
		priorityActions[action].push([func, modifier, noprevent]);
	};

	api.unbind = function(action, func, modifier) {
		var remove = [];
		if (typeof actions[action] != 'undefined') {
			$.each(actions[action], function(i, funcmod) {
				if (func === funcmod[0] && modifier === funcmod[1]) {
					remove.unshift(i);
				}
			});
		}
		$.each(remove, function() {
			actions[action].splice(this, 1);
		});
		remove = [];
		if (typeof priorityActions[action] != 'undefined') {
			$.each(priorityActions[action], function(i, funcmod) {
				if (func === funcmod[0] && modifier === funcmod[1]) {
					remove.unshift(i);
				}
			});
		}
		$.each(remove, function() {
			priorityActions[action].splice(this, 1);
		});
	}

	$(window).bind('keydown', keyListener);

	return api;
})();

/**
 * Public class for UI actions.
 * @param actions
 */
UI.ActionHandler = function(actions) {

	this.dispatch = function(type, target) {
		var listener, argus;
		listener = actions[type];
		if (!listener) {
			return;
		}
		argus = arguments[2];
		$(target).each(function() {
			listener.apply(this, argus);
		});
	};

};

/**
 * Window manager. Currently no public methods.
 */
UI.WindowManager = (function() {
	var api = {}, resizeTimer;

	function windowResizeComplete() {
		$(window).uiAction('windowResizeComplete');
	}

	/**
	 * One resize listener to rule them all.
	 */
	function windowResized() {
		$(window).uiAction('windowResize');
		if (resizeTimer) {
			clearTimeout(resizeTimer);
		}
		resizeTimer = setTimeout(windowResizeComplete, 300);
	}
	$(window).resize(windowResized);

	return api;
})();

/**
 * Manages Ajax history (to a certain degree).
 */
UI.HistoryManager = (function() {

	var api = {},
		lastHash = '',
		lastUri = '',
		ignoreHashChange = false,
		localHistory = [],
		statePushed = false;

	function checkIfHashChanged() {
		var newHash = location.hash;
		if (newHash === lastHash) {
			return;
		}
		onHashChange();
		lastHash = newHash;
	}

	function onHashChange() {
		var hash;
		if (ignoreHashChange) {
			return;
		}
		hash = location.hash;
		if (hash.substr(0, 2) === '#!') {
			location.replace(hash.substr(2));
		}
	}

	function onPopState(e) {
		var state;
		state = e.originalEvent.state;
		if (!state) {
			if (statePushed) {
				location.replace(document.location);
			}
			return;
		}
		if (state.type) {
			UI.ActionManager.trigger(state.type, state);
			return;
		}
		location.replace(document.location);
	}

	function setHash(uri) {
		ignoreHashChange = true;
		location.hash = '!' + uri;
		lastHash = location.hash; // possibly encoded
		ignoreHashChange = false;
	}

	/**
	 * Creates a new history entry for the URI. In pushState-enabled
	 * browsers the address bar will change to match the URI. In older
	 * browsers URI fragments are used.
	 *
	 * Pressing the back/forward buttons will relocate the page.
	 *
	 * @param {string} uri The URI to switch to.
	 * @param {string} trackingTitle If set, tracking should be done. Use here only when other means are not possible.
	 * @return {UI.HistoryManager} The UI.HistoryManager API.
	 */
	api.pushState = function(uri, trackingTitle, stateData) {
		if (lastUri == uri || uri.match(/cm_ajax/)) {
			return api;
		}
		statePushed = true;
		stateData = stateData || {};
		if (trackingTitle) {
			UI.AnalyticsManager.pageview(uri, trackingTitle);
		}
		lastUri = uri;
		localHistory.push(window.location.pathname+window.location.search);
		if (localHistory.length > 10) {
			localHistory.shift();
		}
		UI.ActionManager.trigger('historyPush', uri);
		if (history.pushState) {
			history.pushState(stateData, '', uri);
		}
		else {
			setHash(uri);
		}
		return api;
	};

	/**
	 * Go back in history (for dialog closing etc)
	 */
	api.popState = function() {
		if (!localHistory.length) {
			return;
		}
		var uri = localHistory.pop();
		lastUri = uri;
		if (typeof sessionStorage != 'undefined') {
			sessionStorage.setItem('activeUri', uri);
		}
		if (history.pushState) {
			history.pushState({}, '', uri);
		}
		else {
			setHash(uri);
		}
		return api;
	};

	// https://developer.mozilla.org/en/DOM/Manipulating_the_browser_history
	if (history.pushState) {
		$(window).on('popstate', onPopState);
	} else if ('onhashchange' in window) {
		// Enable hash checks for pushState-enabled browsers too,
		// in case someone posts a hash link to them
		$(window).on('hashchange', checkIfHashChanged);
	}

	checkIfHashChanged();

	$(document).on('click', 'a.store-history', function(e) {
		e.preventDefault();
		UI.clickEnabled(e.target, function() {
			UI.HistoryManager.pushState($(this).data('historyHref') || $(this).attr('href'));
		}, this);
	});

	return api;

})();

/**
 * Elements can register actions that will be triggered with
 * $([selector]).uiAction('actionname', args)
 */
UI.ActionManager = (function() {
	var api = {}, actions = {}, handlers = {};

	/**
	 * DOM event listener for all the different event types. By default
	 * passes source's value as the first parameter to the action,
	 * except for submit which passes serialized form data.
	 */
	function triggerAction(e) {
		var source = $(e.target).closest('.ui-action-' + e.type),
			actionType = source.data($.camelCase('action-' + e.type));
		if (!actionType) {
			throw new Error('Class ui-action-' + e.type + ' defined, but data-action-' + e.type + ' attribute is missing.');
		}
		if (source.hasClass('disabled')) {
			e.preventDefault();
			return false;
		}
		if (e.type == 'submit') {
			api.trigger(actionType, source[0], source.serializeArray());
		} else if (source){
			api.trigger(actionType, source[0], source.value ? source.val() : source.data('value'));
		}
	}

	/**
	 * Registers a function to listen for a single action.
	 *
	 * If context is defined but not found anymore, action listener is removed.
	 */
	api.register = function(action, fn, context) {
		if (!actions[action]) {
			actions[action] = [];
		}
		actions[action].push([fn, context ? (context.jquery ? context[0] : context) : false]);
	};

	/**
	 * Registers a handler to listen for a set of actions
	 *
	 * Uses dfn.uiaction elements similar to dfn.cmevent
	 */
	api.registerHandler = function(name, handler) {
		if (handlers[name]) {
			throw new Error('Cannot rebind action handler "' + name + '"');
		}
		handlers[name] = handler;
		return api;
	};

	/**
	 * Unbinds single action listener
	 */
	api.unregister = function(action, fn) {
		if (actions[action]) {
			actions[action] = $.grep(actions[action], function(val) {
				return val[0] !== fn;
			});
		}
	};

	/**
	 * Triggers an action, with context bound as this
	 */
	api.trigger = function(action, context) {
		var argus = Array.prototype.slice.call(arguments, 2), wrapit = false;
		if (argus.length == 1 && $.isArray(argus[0])) {
			argus = argus[0];
			wrapit = true;
		}
		if (actions[action]) {
			$.each(actions[action], function() {
				if (this[1] !== false && (!this[1] || !$.contains(document.body, this[1]))) {
					api.unregister(action, this[0]);
					return;
				}
				this[0].apply(context, argus);
			});
		}
		// This allows similar listening as for server events
		$('dfn.uiaction').each(function() {
			var handler, klass,
				thisargus = $.merge([], wrapit ? [argus] : argus);
			handler = $(this).data('UIActionHandler');
			if (!handler) {
				klass = $(this).data('handler');
				handler = handlers[klass];
				if (!handler) {
					throw new Error('Action handler "' + klass + '" does not exist');
				}
				$.data(this, 'UIActionHandler', handler);
			}
			// Add dfn data as the second parameter
			thisargus.unshift($.extend({}, $(this).data()));
			// Add context as the first parameter (source of the action), since action handlers
			// get bound to the targeted element instead of context.
			thisargus.unshift(context);
			handler.dispatch(action, $(this).data('target'), thisargus);
		});
	};

	$.fn.uiAction = function(name) {
		var argus = Array.prototype.slice.call(arguments, 1);
		return this.each(function() {
			api.trigger(name, this, argus);
		});
	};

	return api;
})();

UI.I18nManager = (function() {
	var api = {}, activeLang, languages = {};

	function loadLanguage(lang) {
		$.ajax({
			async: false,
			url: window.UISettings.langsource + lang + '.js',
			type: 'GET',
			dataType: 'script',
			error: function() {
				throw new Error('Unable to load language file for ' + lang);
			}
		});
	}

	api.get = function(set, value) {
		if (!activeLang) {
			api.setLanguage('fi_FI');
		}
		if (!activeLang[set]) {
			throw new Error('Translation set ' + set + ' not found for active language');
		}
		if (!activeLang[set][value]) {
			throw new Error('Value ' + value + ' not found from translation set ' + set);
		}
		return activeLang[set][value];
	};

	api.setLanguage = function(lang) {
		if (!languages[lang]) {
			loadLanguage(lang);
		}
		activeLang = languages[lang];
	};

	api.addLanguage = function(lang, data) {
		languages[lang] = data;
		languages[lang].langcode = lang;
	};

	api.getLanguage = function() {
		if (!activeLang) {
			api.setLanguage('fi_FI');
		}
		return activeLang.langcode;
	};

	return api;
})();

UI.FlareManager = (function() {
	var api = {},
		queue = [],
		timer = null,

		display = function() {
			var flare = $(document.body).children('.flare'), inject = false;
			if (!queue.length) {
				flare.addClass('init');
				clearInterval(timer);
				timer = null;
				return;
			}
			if (!flare.length) {
				flare = $('<div class="flare init"></div>');
				inject = true;
			}
			if (!flare.hasClass('init')) {
				setTimeout(function() {
					flare.addClass('init');
					setTimeout(function() {
						flare.text(queue.shift());
						flare.css({
							marginLeft: -(flare.outerWidth() / 2)
						});
						flare.removeClass('init');
					}, 300);
				}, 0);
			} else {
				flare.text(queue.shift());
				if (inject) {
					$(document.body).prepend(flare);
				}
				flare.css({
					marginLeft: -(flare.outerWidth() / 2)
				});
				setTimeout(function() { flare.removeClass('init') }, 0);
			}
		};

	api.create = function(message, instant) {
		queue.push(message);
		if (timer && instant) {
			clearInterval(timer);
			timer = null;
		}
		if (!timer) {
			timer = setInterval(display, 5000);
			display();
		}
	};

	return api;
})();

UI.MessageManager = (function() {
	var api = {},
		groups = {},
		animating = false,
		queue = [];

	function createMessageGroup(type, message) {
		var group = $('<div class="ui-component ui-component-messagegroup icon-context sticky"><span class="icon icon-18 icon-'+type+' contrasted"></span><span class="count"></span><div class="notification" style="width: 320px;"></div></div>');
		group.data('type', type);
		group.css({
			left: 18,
			width: 0,
			opacity: 0
		});
		$('#message-groups').append(group);
		animating = true;
		group.animate({
			left: 0,
			width: 18,
			opacity: 1
		},{
			duration: UI.SHOW_DURATION,
			complete: function() {
				group.getComponent().addMessage(message);
				animating = false;
			}
		});
		group.getComponent().initialize();
		return group.getComponent();
	}

	function handleQueue() {
		if (queue.length == 0) {
			return;
		}
		if (animating) {
			setTimeout(handleQueue, 1000);
			return;
		}
		var msg = queue.shift();
		api.addMessage(msg[0], msg[1]);
		setTimeout(handleQueue, 1000);
	}

	api.removeGroup = function(type) {
		delete groups[type];
	};

	api.addMessage = function(type, message) {
		if (animating) {
			queue.push([type, message]);
			if (queue.length > 5) {
				queue.shift();
			}
			setTimeout(handleQueue, 1000);
			return;
		}
		if (!groups[type]) {
			groups[type] = createMessageGroup(type, message);
		} else {
			groups[type].addMessage(message);
		}
		return api;
	};

	return api;
})();

UI.AnalyticsManager = (function() {
	// Init ga
	(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
		(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
	m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
	})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

	var api = {};

	api.event = function(category, action, label, value) {
		if (!$(document.body).data('webpropertyId')) {
			return;
		}
		var params = {
				hitType: 'event',
				eventCategory: category,
				eventAction: action
			}, trackingLanguage = $('#language-source').data('language') || $(document.body).data('language');
		if (label) {
			params.eventLabel = label;
		}
		if (value) {
			params.eventValue = value;
		}
		window.ga('create', $(document.body).data('webpropertyId'));
		if (trackingLanguage) {
			params.dimension1 = trackingLanguage;
		}
		return window.ga('send', params);
	};

	api.pageview = function(page, title) {
		if (!$(document.body).data('webpropertyId')) {
			return;
		}
		var params = {
				hitType: 'pageview'
			}, trackingLanguage = $('#language-source').data('language') || $(document.body).data('language');
		if (page) {
			params.page = page;
		}
		if (title) {
			params.title = title;
		}
		window.ga('create', $(document.body).data('webpropertyId'));
		if (trackingLanguage) {
			params.dimension1 = trackingLanguage;
		}
		return window.ga('send', params);
	};

	return api;
})();

$.scrollbarWidth = function() {
	var w1, w2,
		div = $('<div style="width:50px;height:50px;overflow:hidden;position:absolute;top:200px;left:200px;z-index: 10500;"><textarea rows="2" cols="20"></textarea><div style="height:100px;"></div></div>');
	$('body').append(div);
	w1 = div[0].offsetWidth;
	div.css('overflow-y', 'scroll');
	w2 = div[0].clientWidth;
	if (w1 - w2 == 0) {
		$('textarea', div).attr('wrap', 'off');
		w1 = $('textarea', div).innerHeight();
		$('textarea', div).attr('wrap', 'soft');
		w2 = $('textarea', div).innerHeight();
	}
	$(div).remove();
	$('body').data('scrollbarWidth', w1 - w2);
	$.scrollbarWidth = function() {
		return $('body').data('scrollbarWidth');
	};
	return $.scrollbarWidth();
};

$.fn.hasScrollbar = function(which) {
	if ((this.css('overflow-y') == 'visible' || this.css('overflow-y') == 'hidden') && (this.css('overflow-x') == 'visible' || this.css('overflow-x') == 'hidden')) {
		return false;
	}
	switch (which) {
		case 'y':
			if (this.css('overflow-y') != 'visible' && this.css('overflow-y') != 'hidden' && this[0].scrollHeight > this[0].clientHeight) {
				return true;
			}
			break;
		case 'x':
			if (this.css('overflow-x') != 'visible' && this.css('overflow-x') != 'hidden' && this[0].scrollWidth > this[0].clientWidth) {
				return true;
			}
			break;
		default:
			if (this.css('overflow-y') != 'visible' && this.css('overflow-y') != 'hidden' && this[0].scrollHeight > this[0].clientHeight || this.css('overflow-x') != 'visible' && this.css('overflow-x') != 'hidden' && this[0].scrollWidth > this[0].clientWidth) {
				return true;
			}
	}
	return false;
};

$.fn.scrollTo = function(opts) {
	var scrolled = false, to = this, container,
		options = {
			complete: $.noop,
			offset: 0
		};
	if (opts) {
		options = $.extend(options, opts);
	}
	this.parents().each(function() {
		var cont = $(this);
		if (!scrolled && cont.hasScrollbar()) {
			scrolled = true;
			container = cont;
		}
	});
	if (!scrolled) {
		$.proxy(options.complete, to)();
	} else if (container && container.length) {
		setTimeout(function() {
			if (container.offset().top + container.height() < to.offset().top + to.outerHeight() + options.offset) {
				container.stop().animate({scrollTop: Math.floor((to.offset().top + container.scrollTop()) - container.offset().top - container.height() + to.outerHeight() + options.offset)}, {
					duration: UI.HIDE_DURATION,
					complete: $.proxy(options.complete, to)
				});
			} else if (container.offset().top > to.offset().top) {
				container.stop().animate({scrollTop: (to.offset().top + container.scrollTop()) - container.offset().top}, {
					duration: UI.HIDE_DURATION,
					complete: $.proxy(options.complete, to)
				});
			} else {
				$.proxy(options.complete, to)();
			}
		}, 0);
	}
};

export default UI;
