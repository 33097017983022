/**
 * make file modules:
 * foundation/vendor/modernizr.js 
 * foundation/vendor/jquery.js 
 * foundation/vendor/jquery.requestAnimationFrame.js 
 * foundation/vendor/jquery.touchwipe.1.1.1.js 
 * foundation/vendor/fastclick.js 
 * core.js openstudio.js 
 * foundation/foundation.js
 * ../flowplayer/flowplayer.min.js
 */

import 'vendor/foundation/foundation.js';
import 'vendor/flowplayer/flowplayer.js';

import './openstudio.js';